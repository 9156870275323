import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import dateformat from "dateformat";
import ReactHtmlParser from "react-html-parser";

import Link from "../../Link";
import styles from "./styles";

import { formatDateToNames } from "../../Calendar/helpers";

const useStyles = makeStyles((theme) => styles(theme));

export default function Calendar(props) {
  const now = new Date();
  const currentMonth = dateformat(now, "mmmm").toLowerCase();
  const currentYear = Number(dateformat(now, "yyyy"));

  const [selectedMonth, setMonth] = useState(currentMonth);
  const [selectedYear, setYear] = useState(currentYear);
  const [searching, setSearching] = useState(false); //eslint-disable-line

  const classes = useStyles();
  function handleMonth(e) {
    if (e) e.preventDefault();
    if (e.target.type !== "radio") return false;
    const year = Number(e.target.getAttribute("year"));

    setYear(year);
    setMonth(e.target.value);
  }

  function getMonthNumberFromName(month) {
    return months.map((m) => m.toLowerCase()).indexOf(month) + 1;
  }

  // function getMonthNameFromNumber(month) {
  //   return months[month - 1];
  // }

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  function renderCourseDates() {
    const output = props.courseDates
      .map((course) => {
        const { id, uri } = course;

        if (!course.courseData.course) return null;
        const {
          startDate,
          endDate,
          place,
          seatsBooked,
          seatsAvailable,
        } = course.courseData;
        const startMonth = Number(startDate.substr(4, 2));
        const endMonth = Number(endDate.substr(4, 2));
        const startYear = Number(startDate.substr(0, 4));
        const endYear = Number(endDate.substr(0, 4));
        const fullyBooked =
          seatsAvailable !== null && seatsAvailable - seatsBooked === 0;

        const formatedCurrentMonth = getMonthNumberFromName(selectedMonth);

        const databaseId = course.courseData.course.databaseId;

        if (startYear !== selectedYear && endYear !== selectedYear) return null;
        if (databaseId !== props.databaseId) return null;
        if (
          !(
            formatedCurrentMonth >= startMonth &&
            formatedCurrentMonth <= endMonth
          )
        )
          return null;

        return (
          <div
            key={id}
            className={
              seatsAvailable !== null && seatsAvailable - seatsBooked === 0
                ? "course-item full"
                : "course-item"
            }
          >
            <div className="dates">
              <span className="title">Dates</span>
              <span>
                {ReactHtmlParser(
                  formatDateToNames(startDate)
                    .replace("[]", "<span>")
                    .replace("[/]", "</span>")
                )}{" "}
                -{" "}
                {ReactHtmlParser(
                  formatDateToNames(endDate)
                    .replace("[]", "<span>")
                    .replace("[/]", "</span>")
                )}
              </span>
            </div>

            <div className="location">
              <span className="title">Location</span>
              <span>{place?.title}</span>
            </div>
            <div className="spaces">
              {seatsAvailable !== null && (
                <>
                  <span className="title">Spaces</span>
                  <span>
                    <strong className={seatsAvailable - seatsBooked === 0 ? "full" : ""}>
                      {seatsAvailable - seatsBooked}
                    </strong>
                    /{seatsAvailable}
                  </span>
                </>
              )}
            </div>
            <div className="link">
              <Link
                className="enquiry"
                to={fullyBooked ? "" : "/course-enquiry"}
                state={{
                  courseId: course.courseData.course.id,
                  dateId: id,
                  startDate,
                  endDate,
                }}
              >
                {fullyBooked ? "Fully Booked" : "Book Today"}
              </Link>
            </div>
          </div>
        );
      })
      .filter((courseDates) => courseDates);

    if (output.length) {
      return output;
    } else if (!searching) {
      return (
        <p>
          We currently don't have any courses scheduled for this month. Please
          check other months or{" "}
          <Link to="/course-enquiry" state={{ courseId: props.id }}>
            get in touch
          </Link>
          .
        </p>
      );
    } else {
      return <p>Loading...</p>;
    }
  }

  return (
    <div className={classes.availListing}>
      <form className="month-select" onClick={(e) => handleMonth(e)}>
        <button
          className={`${currentYear === selectedYear ? "is-active" : ""}`}
        >
          {currentYear}
        </button>
        {months.slice(getMonthNumberFromName(currentMonth) - 1).map((month) => {
          const slug = month.toLowerCase();
          return (
            <span key={slug} className="month">
              <label
                name="month"
                htmlFor={slug}
                className={`${selectedMonth === slug ? "active" : ""}`}
              >
                {month}
                <input
                  type="radio"
                  name="month"
                  value={slug}
                  year={currentYear}
                />
              </label>
            </span>
          );
        })}
        <button
          className={`${currentYear + 1 === selectedYear ? "is-active" : ""}`}
        >
          {currentYear + 1}
        </button>
        {months
          .slice(0, getMonthNumberFromName(currentMonth) - 1)
          .map((month) => {
            const slug = month.toLowerCase();
            return (
              <span key={slug} className="month">
                <label
                  name="month"
                  htmlFor={slug}
                  className={`${selectedMonth === slug ? "active" : ""}`}
                >
                  {month}
                  <input
                    type="radio"
                    name="month"
                    value={slug}
                    year={currentYear + 1}
                  />
                </label>
              </span>
            );
          })}
      </form>

      <div className="listing-wrap">
        <div className="course-titles">
          <div className="dates">Dates</div>
          <div className="location">Location</div>
          <div className="spaces">Spaces Left</div>
        </div>
        <div className="course-listing">{renderCourseDates()}</div>
      </div>
    </div>
  );
}
