export default theme => ({
    courseRelated: {
        position: 'relative',
        paddingTop: 32,
        [theme.breakpoints.up('md')]: {
            paddingTop: 120,
            '& .image-link-row': {
                '& .MuiGrid-root:nth-of-type(2)': {
                    '& .image-link': {
                        marginBottom: 50,
                    }
                }
            }
        },
        '& h2': {
            marginBottom: 50,
        },
        '& .course-titles': {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                fontFamily: "Roboto Condensed",
                letterSpacing: '0.152em',
                lineHeight: 1.2,
                fontSize: 12,
                textTransform: 'uppercase',
                '& .provider': {
                    flex: '0 0 10%',
                    minWidth: '10%',
                    padding: '0 12px 30px 0',
                },
                '& .title': {
                    flex: '0 0 30%',
                    minWidth: '30%',
                    padding: '0 12px 30px',
                },
                '& .location': {
                    flex: '0 0 20%',
                    minWidth: '20%',
                    padding: '0 12px 30px',
                },
                '& .category': {
                    flex: '0 0 15%',
                    minWidth: '15%',
                    padding: '0 12px 30px',
                },
                '& .length': {
                    flex: '0 0 10%',
                    minWidth: '10%',
                    padding: '0 12px 30px',
                },
                '& .dates': {
                    flex: '0 0 15%',
                    minWidth: '15%',
                    padding: '0 12px 30px',
                }
            },
            [theme.breakpoints.up('lg')]: {
                '& .provider': {
                    flex: '0 0 12.5%',
                    minWidth: '12.5%',
                },
                '& .title': {
                    flex: '0 0 31.25%',
                    minWidth: '31.25%',
                },
                '& .location': {
                    flex: '0 0 12.5%',
                    minWidth: '12.5%',
                },
                '& .category': {
                    flex: '0 0 12.5%',
                    minWidth: '12.5%',
                },
                '& .length': {
                    flex: '0 0 6.25%',
                    minWidth: '6.25%',
                },
                '& .dates': {
                    flex: '0 0 25%',
                    minWidth: '25%',
                }
            }
        },
        '& .course-listing': {
            marginBottom: 32,
            [theme.breakpoints.up('md')]: {
                marginBottom: 80,
            },
            '& .course-item': {
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                borderTop: '1px solid rgba(0,0,0,0.3)',
                backgroundColor: 'transparent',
                '&:nth-of-type(odd)': {
                    backgroundColor: 'white',
                },
                '& span.title': {
                    fontFamily: "Roboto Condensed",
                    letterSpacing: '0.152em',
                    lineHeight: 1.2,
                    fontSize: 12,
                    opacity: .3,
                    display: 'block',
                    marginBottom: 5,
                    textTransform: 'uppercase',
                    [theme.breakpoints.up('md')]: {
                        display: 'none',
                    }
                },
                '& .provider': {
                    flex: '0 0 100%',
                    minWidth: '100%',
                    padding: 12,
                    '& .gatsby-image-wrapper': {
                        width: 80,
                    }
                },
                '& div.title': {
                    flex: '0 0 100%',
                    minWidth: '100%',
                    padding: 12,
                    '& a': {
                        fontFamily: 'Overpass',
                        letterSpacing: '0.016em',
                        lineHeight: 1.5,
                        fontSize: 16,
                        fontWeight: 'bold',
                        color: theme.palette.primary.main,
                    }
                },
                '& .location': {
                    flex: '0 0 50%',
                    minWidth: '50%',
                    padding: 12,
                    fontFamily: 'Overpass',
                    letterSpacing: '0.016em',
                    lineHeight: 1.5,
                    fontSize: 16,
                    opacity: .5
                },
                '& .category': {
                    flex: '0 0 50%',
                    minWidth: '50%',
                    padding: 12,
                    fontFamily: 'Overpass',
                    letterSpacing: '0.016em',
                    lineHeight: 1.5,
                    fontSize: 16,
                    opacity: .5
                },
                '& .length': {
                    flex: '0 0 50%',
                    minWidth: '50%',
                    padding: 12,
                    fontFamily: 'Overpass',
                    letterSpacing: '0.016em',
                    lineHeight: 1.5,
                    fontSize: 16,
                    opacity: .5
                },
                '& .dates': {
                    flex: '0 0 50%',
                    minWidth: '50%',
                    padding: 12,
                    fontFamily: 'Overpass',
                    letterSpacing: '0.016em',
                    lineHeight: 1.5,
                    fontSize: 16,
                    opacity: .5
                },
                '& .link': {
                    flex: '0 0 100%',
                    minWidth: '100%',
                    padding: 12,
                    paddingBottom: 32,
                    '& a': {
                        display: 'table',
                        margin: '0 auto',
                        backgroundColor: 'white',
                        color: theme.palette.primary.main,
                        textTransform: 'uppercase',
                        fontFamily: theme.headerFont,
                        fontSize: 16,
                        lineHeight: 1.5,
                        borderRadius: 15,
                        boxShadow: '0 3px 6px rgba(0,0,0,0.16)',
                        textDecoration: 'none',
                        padding: '5px 20px',
                        transition: 'background-color .3s ease-in-out, color .3s ease-in-out',
                        '&:hover, &:focus': {
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                        }
                    }
                },
                [theme.breakpoints.up('md')]: {
                    '& .provider': {
                        flex: '0 0 10%',
                        minWidth: '10%',
                        padding: '24px 12px 32px',
                    },
                    '& div.title': {
                        flex: '0 0 30%',
                        minWidth: '30%',
                        padding: '24px 12px 32px',
                    },
                    '& .location': {
                        flex: '0 0 20%',
                        minWidth: '20%',
                        padding: '24px 12px 32px',
                    },
                    '& .category': {
                        flex: '0 0 15%',
                        minWidth: '15%',
                        padding: '24px 12px 32px',
                    },
                    '& .length': {
                        flex: '0 0 10%',
                        minWidth: '10%',
                        padding: '24px 12px 32px',
                    },
                    '& .dates': {
                        flex: '0 0 15%',
                        minWidth: '15%',
                        padding: '24px 12px 32px',
                    }
                },
                [theme.breakpoints.up('lg')]: {
                    '& .provider': {
                        flex: '0 0 12.5%',
                        minWidth: '12.5%',
                    },
                    '& div.title': {
                        flex: '0 0 31.25%',
                        minWidth: '31.25%',
                    },
                    '& .location': {
                        flex: '0 0 12.5%',
                        minWidth: '12.5%',
                    },
                    '& .category': {
                        flex: '0 0 12.5%',
                        minWidth: '12.5%',
                    },
                    '& .length': {
                        flex: '0 0 6.25%',
                        minWidth: '6.25%',
                    },
                    '& .dates': {
                        flex: '0 0 12.5%',
                        minWidth: '12.5%',
                    },
                    '& .link': {
                        flex: '0 0 12.5%',
                        minWidth: '12.5%',
                        padding: '24px 12px 32px',
                    }
                }
            }
        }
    },
})