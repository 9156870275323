export default theme => ({
    courseAvailability: {
        position: 'relative',
        marginBottom: 36,
        '&::before': {
            backgroundColor: theme.palette.primary.main,
            content: '""',
            position: 'absolute',
            top: 90,
            bottom: 90,
            left: '50%',
            width: '100vw',
            transform: 'translateX(-50%)',
            zIndex: -1,
        },
        '& .course-wrapper': {
            backgroundColor: 'white',
            padding: 32,
            boxShadow: '',
            marginTop: 32,
            marginBottom: 32,
            [theme.breakpoints.up('md')]: {
                padding: 80,
                marginTop: 80,
                marginBottom: 80,
            }
        },
        '& p': {
            '& a': {
                color: theme.palette.primary.main,
            }
        }
    },
    availListing: {
        marginTop: 36,
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
        },
        '& .month-select': {
            whiteSpace: 'nowrap',
            height: 45,
            overflowX: 'scroll',
            overflowY: 'hidden',
            [theme.breakpoints.up('sm')]: {
                flex: '0 0 180px',
                maxWidth: '180px',
                height: 'auto',
                whiteSpace: 'normal',
                overflow: 'hidden',
            },
            '& button': {
                border: 'none',
                backgroundColor: 'transparent',
                borderBottom: '4px solid ' + theme.palette.secondary.main,
                fontFamily: theme.headerFont,
                fontSize: 16,
                display: 'inline-block',
                verticalAlign: 'bottom',
                padding: '3px 10px',
                [theme.breakpoints.up('sm')]: {
                    display: 'block',
                    borderBottom: 'none',
                    borderRight: '4px solid ' + theme.palette.secondary.main,
                    width: 160,
                    textAlign: 'right',
                    fontSize: 24,
                    padding: 24,
                },
            },
            '& .month': {
                position: 'relative',
                '& label': {
                    borderBottom: '4px solid ' + theme.palette.secondary.main,
                    fontSize: 12,
                    letterSpacing: '0.016em',
                    lineHeight: 1.5,
                    fontFamily: 'Overpass',
                    display: 'inline-block',
                    cursor: 'pointer',
                    padding: '5px 20px',
                    position: 'relative',
                    [theme.breakpoints.up('sm')]: {
                        display: 'block',
                        borderBottom: 'none',
                        borderRight: '4px solid ' + theme.palette.secondary.main,
                        width: 160,
                        textAlign: 'right',
                        fontSize: 16,
                    },
                    '&.active': {
                        backgroundColor: theme.palette.secondary.main,
                        fontWeight: 'bold',
                        textShadow: '2px 0 black',
                        color: theme.palette.secondary.contrastText,
                        '&::after': {
                            position: 'absolute',
                            content: '""',
                            display: 'block',
                            top: '100%',
                            left: 'calc(50% - 15px)',
                            width: 0,
                            height: 0,
                            borderStyle: 'solid',
                            borderWidth: '50px 15px 0 15px',
                            borderColor: theme.palette.secondary.main + ' transparent transparent transparent',
                            [theme.breakpoints.up('sm')]: {
                                top: 0,
                                left: 'calc(100% + 4px)',
                                borderWidth: '17px 0 17px 65px',
                                borderColor: 'transparent transparent transparent ' + theme.palette.secondary.main,
                            },
                        },
                    },
                },    
                '& input': {
                    position: 'absolute',
                    top: -6,
                    left: 0,
                    opacity: 0,
                    width: '100%',
                    height: '28px',
                    margin: 0,
                    cursor: 'pointer',
                    [theme.breakpoints.up('sm')]: {
                        width: 156,
                        height: 34,
                    }
                }
            }
        },
        '& .listing-wrap': {
            borderBottom: '1px solid rgba(0,0,0,0.3)',
            [theme.breakpoints.up('sm')]: {
                flex: '0 0 calc(100% - 215px)',
                minWidth: 'calc(100% - 215px)',
            },
            '& .course-titles': {
                display: 'none',
                [theme.breakpoints.up('md')]: {
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    fontFamily: "Roboto Condensed",
                    letterSpacing: '0.152em',
                    lineHeight: 1.2,
                    fontSize: 10,
                    opacity: .3,
                    textTransform: 'uppercase',
                    '& .dates': {
                        flex: '0 0 55.55%',
                        minWidth: '55.55%',
                        padding: '6px 20px',
                    },
                    '& .location, & .spaces': {
                        flex: '0 0 22.222%',
                        minWidth: '22.222%',
                        padding: '6px 20px',
                    },
                },
                [theme.breakpoints.up('lg')]: {
                    '& .dates': {
                        flex: '0 0 45.45%',
                        minWidth: '45.45%',
                    },
                    '& .location, & .spaces': {
                        flex: '0 0 18.18%',
                        minWidth: '18.18%',
                    },
                }
            },
            '& .course-item': {
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                borderTop: '1px solid rgba(0,0,0,0.3)',
                backgroundColor: 'transparent',
                alignItems: 'center',
                '&:nth-of-type(even)': {
                    backgroundColor: '#ededed4d',
                },
                '&.full *': {
                    filter: 'grayscale(1)',
                    opacity: .5,
                },
                '&.full a': {
                    cursor: 'not-allowed',
                },
                '& span.title': {
                    fontFamily: "Roboto Condensed",
                    letterSpacing: '0.152em',
                    lineHeight: 1.2,
                    fontSize: 10,
                    opacity: .3,
                    display: 'block',
                    marginBottom: 5,
                    color: '#0F0F0F',
                    textTransform: 'uppercase',
                    [theme.breakpoints.up('sm')]: {
                        display: 'none',
                    }
                },
                '& .dates': {
                    fontFamily: theme.headerFont,
                    color: theme.palette.secondary.main,
                    fontSize: 16,
                    letterSpacing: '0.016em',
                    lineHeight: 1.5,
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                    padding: 12,
                    flex: '0 0 100%',
                    minWidth: '100%',
                },
                '& .location, & .spaces': {
                    flex: '0 0 50%',
                    minWidth: '50%',
                    padding: 12,
                    fontFamily: 'Overpass',
                    letterSpacing: '0.016em',
                    lineHeight: '16px',
                    fontSize: 12,
                    '& strong': {
                        fontWeight: 'bold',
                        fontSize: 13,
                        width: 25,
                        color: '#279B1E',
                        display: 'inline-block',
                        '&.full': {
                            color: theme.palette.secondary.main,
                        }
                    }
                },
                '& .link': {
                    flex: '0 0 100%',
                    minWidth: '100%',
                    padding: 12,
                    paddingBottom: 32,
                    '& a': {
                        display: 'table',
                        margin: '0 auto',
                        backgroundColor: theme.palette.secondary.main,
                        color: theme.palette.secondary.contrastText,
                        textTransform: 'uppercase',
                        fontFamily: theme.headerFont,
                        fontSize: 16,
                        lineHeight: 1.5,
                        borderRadius: 15,
                        boxShadow: '0 3px 6px rgba(0,0,0,0.16)',
                        textDecoration: 'none',
                        padding: '5px 20px',
                        transition: 'background-color .3s ease-in-out, color .3s ease-in-out',
                        '&:hover, &:focus': {
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                        }
                    }
                },
                [theme.breakpoints.up('md')]: {
                    '& .dates': {
                        flex: '0 0 55.55%',
                        minWidth: '55.55%',
                        padding: 20,
                    },
                    '& .location, & .spaces': {
                        flex: '0 0 22.222%',
                        minWidth: '22.222%',
                        padding: 20,
                    },
                    '& .link': {
                        padding: '0 20px 20px',
                    }
                },
                [theme.breakpoints.up('lg')]: {
                    '& .dates': {
                        flex: '0 0 45.45%',
                        minWidth: '45.45%',
                    },
                    '& .location, & .spaces, & .link': {
                        flex: '0 0 18.18%',
                        minWidth: '18.18%',
                        padding: 20,
                    },
                }
            }
        }
    },
    cta: {
        display: 'block',
        borderRadius: 25,
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.main,
        boxShadow: '0 3px 6px rgba(0,0,0,0.16)',
        padding: '10px 40px',
        textAlign: 'center',
        letterSpacing: '0.016em',
        fontSize: 18,
        fontFamily: theme.headerFont,
        margin: '24px 24px 10px',
        textDecoration: 'none',
        transition: 'background-color .3s ease-in-out',
        [theme.breakpoints.up('md')]: {
            margin: '0 0 10px 0',
            width: 195,
        }
    }
})