import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import styles from "./styles";
import { makeStyles } from "@material-ui/styles";
import dateformat from "dateformat";

import { CgbTabSeparatorBlock } from "../../blocks/CgbTabSeparatorBlock";
import { CoreHeadingBlock } from "../../blocks/CoreHeadingBlock";
import Calendar from "./Calendar";
import { makeDateKey } from "../../Calendar/helpers";
import { fetchCourseDates } from "../../Calendar/actions";

const useStyles = makeStyles((theme) => styles(theme));

const CourseAvailability = ({ id, databaseId, type }) => {
  const [courseDates, setCourseDates] = useState({});

  async function retrieveCourseDates(databaseId) {
    const now = new Date();
    const currentMonth = dateformat(now, "m") - 1;
    const currentYear = dateformat(now, "yyyy");
    const startDate = makeDateKey(currentYear, currentMonth);
    const endDate = "20300101";
    const courseDates = await fetchCourseDates("", [], startDate, endDate, databaseId );
    const sortedCourses = courseDates
      .map((courseDate) => courseDate.node)
      .sort( (a, b) => parseInt(a.courseData.startDate) - parseInt(b.courseData.startDate) );

    setCourseDates(sortedCourses);
    return sortedCourses;
  }

  // Fetch course dates when necessary
  useEffect(() => {
    retrieveCourseDates(databaseId);
  }, []); //eslint-disable-line

  const classes = useStyles();
  const now = new Date();
  let hasDates = false;
  if (Object.keys(courseDates).length > 0) {
    hasDates = courseDates.
      filter((date) => {
        const { startDate } = date.courseData;
        if (!startDate) return false;
        const startDateEpoch = new Date(startDate.substring(0, 4) + '-' + startDate.substring(4, 6) + '-' + startDate.substring(6, 8)).getTime();
        const nowEpoch = now.getTime();

        if (nowEpoch > startDateEpoch) return false;

        return date;
      })  
      .length;
  }

  if (!hasDates) {
    return (
      <div className={`course-availability mb-0 ${classes.courseAvailability}`}>
        <CgbTabSeparatorBlock
          attributes={{
            bottomColor: "primary",
            className: "",
            flipTabClass: "",
            topColor: "white",
          }}
          innerBlocks={[]}
        />
        <div className="course-wrapper">
          <CoreHeadingBlock
            attributes={{
              align: "",
              anchor: "",
              className: "",
              content: "Availability",
              level: 2,
              textColor: "primary",
              backgroundColor: "",
              __typename: "WpCoreHeadingBlockAttributes",
            }}
            innerBlocks={[]}
          />
          <p>
            There aren't currently any dates available for this course. New
            dates will be coming soon, please check back later.
          </p>
          <Link
            to="/course-enquiry"
            className={classes.cta}
            state={{ courseId: id }}
          >
            contact us
          </Link>
        </div>
        <CgbTabSeparatorBlock
          attributes={{
            bottomColor: "background",
            className: "",
            flipTabClass: " flip-tab",
            topColor: "primary",
          }}
          innerBlocks={[]}
        />
      </div>
    );
  } else if (!hasDates) {
    return null;
  }

  return (
    <div className={`course-availability mb-0 ${classes.courseAvailability}`}>
      <CgbTabSeparatorBlock
        attributes={{
          bottomColor: "primary",
          className: "",
          flipTabClass: "",
          topColor: "white",
        }}
        innerBlocks={[]}
      />
      <div className="course-wrapper">
        <CoreHeadingBlock
          attributes={{
            align: "",
            anchor: "",
            className: "",
            content: "Availability",
            level: 2,
            textColor: "primary",
            backgroundColor: "",
            __typename: "WpCoreHeadingBlockAttributes",
          }}
          innerBlocks={[]}
        />
        <Calendar databaseId={databaseId} id={id} courseDates={courseDates} />
      </div>
      <CgbTabSeparatorBlock
        attributes={{
          bottomColor: "background",
          className: "",
          flipTabClass: " flip-tab",
          topColor: "primary",
        }}
        innerBlocks={[]}
      />
    </div>
  );
};

export default CourseAvailability;
