import React, { Component } from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Breadcrumb from "../components/Breadcrumb"
import CourseOverview from "../components/Course/CourseOverview"
import CourseBanner from "../components/Course/CourseBanner"
import CourseSignUp from "../components/Course/CourseSignUp"
import CourseAvailability from "../components/Course/CourseAvailability"
import CourseRelatedCourses from "../components/Course/CourseRelatedCourses"

class Course extends Component {
  render() {
    let catStack = []

    if (this.props.data.wpCourse.courseCategories !== null) {
      if (this.props.data.wpCourse.courseCategories.nodes.length === 1) {
        catStack.push(this.props.data.wpCourse.courseCategories.nodes[0])
      } else {
        this.props.data.wpCourse.courseCategories.nodes.forEach(category => {
          if (category.ancestors !== null) {
            if (category.ancestors.nodes.length >= catStack.length) {
              catStack = [
                { id: category.id, name: category.name, slug: category.slug },
              ]
              category.ancestors.nodes.forEach(ancestor => {
                catStack.push(ancestor)
              })
            }
          }
        })
      }
    }

    if (catStack.length > 1) {
      catStack.reverse()
    }

    return (
      <Layout
        meta={{
          ...this.props.data.wpCourse.Meta,
          canonical: this.props.data.wpCourse.uri,
        }}
        path={this.props.pageContext.pagePath}
        cta={this.props.data.wpCourse.PageCTA}
        themeOptions={this.props.data.wp.themeOptions}
      >
        <Breadcrumb
          type="course"
          current={{
            title: this.props.data.wpCourse.title,
            slug: this.props.data.wpCourse.slug,
            uri: this.props.data.wpCourse.uri,
          }}
          courseCategories={this.props.data.wpCourse.courseCategories.nodes}
        />
        <CourseOverview
          id={this.props.data.wpCourse.id}
          title={this.props.data.wpCourse.title}
          provider={this.props.data.wpCourse.courses.provider}
          code={this.props.data.wpCourse.courses.code}
          image={this.props.data.wpCourse.courses.image}
          desc={this.props.data.wpCourse.courses.description}
          memberPrice={this.props.data.wpCourse.courses.memberPrice}
          networkPoints={this.props.data.wpCourse.courses.networkPoints}
          tradePrice={this.props.data.wpCourse.courses.tradePrice}
          downloads={this.props.data.wpCourse.courses.downloads}
          path={this.props.pageContext.pagePath}
          length={this.props.data.wpCourse.courses.length}
          credits={this.props.data.wpCourse.courses.credits}
          databaseId={this.props.data.wpCourse.databaseId}
          courseDates={this.props.data.allWpCourseDate}
        />
        <CourseAvailability
          id={this.props.data.wpCourse.id}
          databaseId={this.props.data.wpCourse.databaseId}
          courseDates={this.props.data.allWpCourseDate}
        />
        <CourseRelatedCourses
          databaseId={this.props.data.wpCourse.databaseId}
          courses={this.props.data.wpCourse.courses.relatedCourses}
          courseDates={this.props.data.allWpCourseDate}
        />
        <CourseBanner />
        <CourseSignUp />
      </Layout>
    )
  }
}

export const query = graphql`
  query($id: String) {
    wpCourse(id: { eq: $id }) {
      id
      databaseId
      slug
      title
      uri
      Meta {
        fieldGroupName
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
        twitterImage {
          uri
        }
        ogImage {
          uri
        }
      }
      courses {
        image
        code
        description
        memberPrice
        tradePrice
        networkPoints
        type
        length
        credits
        downloads {
          title
          file
        }
        provider {
          courseProviderInfo {
            logo {
              localFile {
                childImageSharp {
                  fluid(quality: 80, maxWidth: 1660) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          name
        }
        relatedCourses {
          ... on WpCourse {
            id
            databaseId
            title
            uri
            courses {
              type
              length
              places {
                ... on WpPlace {
                  title
                }
              }
              provider {
                courseProviderInfo {
                  logo {
                    localFile {
                      childImageSharp {
                        fluid(quality: 80, maxWidth: 1660) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
                name
              }
            }
            courseCategories {
              nodes {
                id
                name
              }
            }
          }
        }
      }
      courseCategories {
        nodes {
          id
          name
          slug
          uri
          ancestors {
            nodes {
              id
              name
              slug
              uri
            }
          }
        }
      }
    }
    allWpCourseDate {
      edges {
        node {
          databaseId
          courseData {
            startDate
            endDate
            course {
              ... on WpCourse {
                id
                databaseId
              }
            }
          }
        }
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`

export default Course
