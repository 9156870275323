
export default theme => ({
    calloutCta: {
        position: 'relative',
        marginBottom: 36,
        padding: '50px 0',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            alignItems: 'flex-start',
            width: 'calc(100% + 24px)',
            margin: '0 -12px 26px',
        },
        '&::before': {
            content: '""',
            display: 'block',
            backgroundColor: theme.palette.secondary.main,
            position: 'absolute',
            width: '100vw',
            left: '50%',
            top: 0,
            bottom: 0,
            transform: 'translateX(-50%)',
            zIndex: -1,
        },
        '&.contained': {
            '&::before': {
                width: 'auto',
                left: -24,
                right: -24,
                transform: 'none',
            }
        },
        '& .MuiTypography-body1': {
            fontSize: 24,
            lineHeight: 1.66,
            color: theme.palette.secondary.contrastText,
            marginBottom: 44,
            letterSpacing: 0,
            fontFamily: theme.subHeaderFont,
            [theme.breakpoints.up('md')]: {
                flex: '0 0 66.666%',
                maxWidth: '66.666%',
                marginBottom: 0,
                padding: '0 12px',
                marginRight: '8.333%',
            },
            [theme.breakpoints.up('lg')]: {
                marginLeft: '6.25%',
                flex: '0 0 37.5%',
                maxWidth: '37.5%',
                marginRight: '25%',
            }
        },
        '& > a': {
            [theme.breakpoints.up('md')]: {
                flex: '0 0 25%',
                maxWidth: '25%',
                padding: '0 12px',
            },
            '& button': {
                display: 'block',
                width: '100%',
            }
        }
    },
    calloutCtaTheme: {
        ...theme.calloutCtaTheme,
    }
})