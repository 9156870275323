import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import ReactHtmlParser from "react-html-parser";
import scrollTo from 'gatsby-plugin-smoothscroll';
import styles from './styles';
import Img from "gatsby-image";
import Link from '../../Link';
import CourseShare from '../CourseShare';

import { isLoggedIn } from '../../../services/auth';
import { isBrowser, priceFormatter } from '../../../helpers';

const useStyles = makeStyles(theme => styles(theme));

function Logo(props) {
    // console.log(props);
    if (!props.provider.courseProviderInfo.logo) return null;
    
    return <Img className="brand-logo" fluid={props.provider.courseProviderInfo.logo.localFile.childImageSharp.fluid} />
}

export default function CourseOverview(props) {
    // console.log(props);
    const classes = useStyles();
    const [group, setGroup] = useState(null);
    const [authenticated, setAuthentication] = useState(undefined);

    const now = new Date();

    const hasDates = props.courseDates.edges
        .map(node => node.node)
        .filter(date => date.courseData.course?.databaseId === props.databaseId)
        .filter(date => {
            const { startDate } = date.courseData;
            if (!startDate) return false
            const startDateEpoch = new Date(startDate.substring(0, 4) + '-' + startDate.substring(4, 6) + '-' + startDate.substring(6, 8)).getTime();
            const nowEpoch = now.getTime();

            if (nowEpoch > startDateEpoch) return false;

            return date;
        })
        .length;

    isBrowser && authenticated === undefined && handleAuthentication();

    async function handleAuthentication() {
        const response = await isLoggedIn();

        if (response.data.errors) {
            setAuthentication(false);
        } else {
            setAuthentication(response.data.data.user);

            const group = response.data.data.user.userGroup.group.slug;

            if (group) {
                setGroup(group);
            }
        }
    }

    return (
        <div className={`course-overview  ${classes.courseOverview}  ${classes.courseOverviewTheme}`}>
            <div className="title-wrap mobile">
                <Logo provider={props.provider} />
                <h1>{ReactHtmlParser(props.title)}</h1>
                <p className="code">{props.code}</p>
                <div className={"prices " + group}>
                    <p>Our Pricing</p>
                    {props.memberPrice && group === 'member' && <div className={`price member`}>
                        <span>Member <strong>{priceFormatter(props.memberPrice)}</strong></span>
                    </div>}
                    {props.tradePrice && <div className={`price trade`}>
                        <span>Trade <strong>{priceFormatter(props.tradePrice)}</strong></span>
                    </div>}
                    {props.networkPoints && <div className={`price networkPoints`}>
                        <span>Network Points <strong>{props.networkPoints}</strong></span>
                    </div>}
                </div>
            </div>
            <div className="course-gallery">
                {props.image !== null &&
                    <img src={props.image} alt="" />
                }
                <CourseShare
                    path={props.path}
                    title={props.title}
                />
            </div>
            <div className="overview">
                <div className="title-wrap desktop">
                    <Logo provider={props.provider} />
                    <h1>{ReactHtmlParser(props.title)}</h1>
                    <p className="code">{props.code}</p>
                    <div className={"prices " + group}>
                        <p>Our Pricing</p>
                        {props.memberPrice && group === 'member' && <div className={`price member`}>
                            <span>Member <strong>{priceFormatter(props.memberPrice)}</strong></span>
                        </div>}
                        {props.tradePrice && <div className={`price trade`}>
                            <span>Trade <strong>{priceFormatter(props.tradePrice)}</strong></span>
                        </div>}
                        {props.networkPoints && <div className={`price networkPoints`}>
                            <span>Network Points <strong>{props.networkPoints}</strong></span>
                        </div>}
                    </div>
                </div>
                <div className="actions">
                    {!!hasDates && <a //eslint-disable-line
                    to="#" state={{ id: props.id, code: props.code }} onClick={e => scrollTo(`.course-wrapper`)}>View Course Dates</a>}
                    {!hasDates && <Link to="/course-enquiry" state={{ courseId: props.id, code: props.code }}>Enquire now</Link>}
                </div>
                <div className="overview-content">
                    <h2>Overview</h2>
                    <h3>{ReactHtmlParser(props.title)}</h3>
                    {ReactHtmlParser(props.desc)}
                    <div className="icon-detail">
                        {props.length !== null &&
                            <p className="length"><span>Length: </span> {props.length}</p>
                        }
                        {props.credits !== null &&
                            <p className="credits">{props.credits}</p>
                        }
                    </div>
                    {props.downloads !== null &&
                        <div className="downloads">
                            {props.downloads.map(download => {
                                return <Link to={download.file} key={download.title}>{ReactHtmlParser(download.title)}</Link>
                            })}
                        </div>  
                    }
                </div>
            </div>
        </div>
    )
}