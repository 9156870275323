export default theme => ({
    courseBanner: {
        '& .tab-sep': {
            position: 'relative',
            width: 'calc(100vw + 2px)',
            transform: 'translateX(-50%) rotate(180deg)',
            margin: '-1px 0 -74px 50%',
            zIndex: 1,
            '& .top': {
                height: 74,
                display: 'block',
                width: '100%',
                clipPath: 'polygon(0% 50px, calc(50% - 82px) 50px, calc(50% - 40px) 0, calc(50% + 40px) 0, calc(50% + 82px) 50px, 100% 50px, 100% 100%, 0% 100%)',
                '&.white': {
                    backgroundColor: 'white',
                },
            },
        },
        '& .product-banner': {
            position: 'relative',
            backgroundColor: theme.palette.primary.main,
            marginBottom: 0,
            '& .gatsby-image-wrapper': {
                height: 'auto !important',
                width: '100% !important',
                '& > span': {
                    [theme.breakpoints.up('md')]: {
                        minHeight: 600,
                    }
                }
            },
            [theme.breakpoints.up('md')]: {
                margin: '0 0 0 50%',
                width: '100vw',
                transform: 'translateX(-50vw)',
            },
            '& h1, & h2, & h3, & h4, & h5, & h6, & p, & a': {
                color: theme.palette.secondary.contrastText,
            },
            '& a': {
                textDecoration: 'underline',
                '&:hover, &:focus': {
                    opacity: '0.9',
                }
            },
            '& .con-wrap': {
                zIndex: '2',
                padding: 24,
                [theme.breakpoints.up('md')]: {
                    padding: 60,
                    position: 'absolute',
                    left: '50%',
                    width: 'calc(100vw - 48px)',
                    maxWidth: 1240,
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    '&::before': {
                        content: '""',
                        backgroundColor: theme.palette.primary.main,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        bottom: 0,
                        width: 450,
                        display: 'block',
                        zIndex: -1,
                        clipPath: 'polygon(calc(100% - 62px) 0, 100% 75px, 100% 100%, 0 100%, 0 0)'
                    },
                    '& > *': {
                        maxWidth: 330,
                    },
                    '& p': {
                        marginBottom: 36,
                    }
                },
                '& .gatsby-image-wrapper': {
                    display: 'none !important',
                }
            },
            '&:nth-of-type(even)': {
                [theme.breakpoints.up('md')]: {
                    '& .con-wrap': {
                        '&::before': {
                            left: 'auto',
                            right: 0,
                        },
                        '& > *': {
                            marginLeft: 'auto',
                            marginRight: 0,
                            display: 'block',
                        }
                    },
                }
            }
        }
    },
    imageContentRowsInnerTheme: {
        ...theme.imageContentRowsInnerTheme
    },
})